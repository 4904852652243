import { ScheduleEvent } from 'interfaces/schedule-event';
import { HammrOrganization } from 'interfaces/company';
import { HammrEarningRate } from 'interfaces/earning-rate';
import { WorkersCompCode } from '@/interfaces/WorkersCompCode';
import { Crew } from './crew';

interface BaseUser {
  email: string;
  gender?: Gender;
  ethnicity?: Ethnicity;
  veteranStatus?: VeteranStatus;
  emergencyContactFirstName?: string;
  emergencyContactLastName?: string;
  emergencyContactRelationship?: EmergencyContactRelationship;
  emergencyContactPhone?: string;
  profilePhotoObjectId?: string;
  managerId?: number;
  workerClassification: WorkerClassification;
}

export interface User extends BaseUser {
  id?: number;
  checkCompanyId: string;
  createdAt: Date;
  isCompanyAdmin: boolean;
  name: string;
  companyId: string; // Hammr's orgId
  uid: string;
  employeeId?: string;
  checkEmployeeId?: string;
  jobTitle?: string;
  employmentType?: string;
  pay?: string;
  avatarURL?: string;
  signer_title?: string;
  fullName?: string;
  checkContractorId?: string;
  avatarUrl?: string;
  role?: string;
}

export interface CreateHammrUser extends BaseUser {
  firstName: string;
  lastName: string;
  role?: string;
  position?: string;
  employeeId?: string;
  hourlyRate?: number;
  phoneNumber?: string;
  fromPayroll?: boolean;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  dob?: string;
  workplaces?: string[];
  startDate?: string;
  shouldAddToPayroll?: boolean;
  workersCompCodeId?: number;
  target?: 'employees' | 'contractors';
  salary?: number;
  weeklyHours?: number;
}

export interface UpdateUser extends Partial<BaseUser> {
  checkCompanyId?: string;
  createdAt?: Date;
  isCompanyAdmin?: boolean;
  name?: string;
  companyId?: string;
  uid?: string;
  employeeId?: string;
  checkEmployeeId?: string;
  jobTitle?: string;
  employmentType?: string;
  pay?: string;
  avatarURL?: string;
  firstName?: string;
  lastName?: string;
  isArchived?: boolean;
  phoneNumber?: string;
  address1?: string;
  address2?: string;
  city?: string;
  workersCompCodeId?: number;
  state?: string;
  postalCode?: string;
  dob?: string;
  position?: string;
  role?: string;
  workplaces?: string;
  startDate?: string;
  type: 'employee' | 'contractor'; // required so backand can route effectively
  company: string; // required - check company id
}

export interface HammrUser extends Partial<BaseUser> {
  id: number;
  firstName: string;
  lastName: string;
  role: string;
  position?: string;
  hourlyRate?: number;
  isCompanyAdmin?: boolean;
  createdAt?: Date;
  employeeId?: string;
  checkEmployeeId?: string;
  checkContractorId?: string;
  organizationCheckCompanyId: string;
  phone?: string; // field returned from db
  fullName?: string; // potentially an extended field
  isClockInReminderEnabled?: boolean;
  isClockOutReminderEnabled?: boolean;
  clockInReminderAt?: string;
  clockOutReminderAt?: string;
  organizationId?: number;
  organization?: HammrOrganization;
  isArchived: boolean;
  payrollItemId: number;
  overtimePayrollItemId: number;
  defaultCostCodeId: number;
  workersCompCode?: WorkersCompCode;
  earningRates?: HammrEarningRate[];
  managerId?: number;
  manager?: {
    id: number;
    firstName: string;
    lastName: string;
  };
}

export interface HammrUserCrew extends HammrUser {
  crewMemberUser: { crew: Partial<Crew>; id: number }[];
}

export interface MutateHammrUser extends Partial<BaseUser> {
  id: number;
  firstName: string;
  lastName: string;
  role: string;
  organizationId: number;
  position?: string;
  hourlyRate?: number;
  createdAt?: Date;
  employeeId?: string;
  checkEmployeeId?: string;
  checkContractorId?: string;
  organizationCheckCompanyId: string;
  phoneNumber?: string; // used for create and update reqs
  fromPayroll?: boolean;
  payrollItemId: number;
  overtimePayrollItemId: number;
  defaultCostCodeId: number;
  workersCompCodeId?: number;
  salary?: number;
  weeklyHours?: number;
  payEffectiveDate?: number; // the effective date of salary or hourly rate
}

export interface HammrUserSchedule {
  id: number;
  firstName: string;
  lastName: string;
  scheduleEvents: Partial<ScheduleEvent>[];
}

export interface DraggableHammrUser extends HammrUser {
  crewWasDragged?: boolean;
  crewName?: string;
}

export enum WorkerClassification {
  Employee = 'EMPLOYEE',
  Contractor = 'CONTRACTOR',
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  NonBinary = 'NON_BINARY',
  PreferNotToAnswer = 'PREFER_NOT_TO_ANSWER',
}

export enum Ethnicity {
  AmericanIndian = 'AMERICAN_INDIAN',
  Asian = 'ASIAN',
  BlackOrAfricanAmerican = 'BLACK',
  HispanicOrLatino = 'HISPANIC',
  NativeHawaiianOrOtherPacificIslander = 'OTHER_PACIFIC_ISLANDER',
  White = 'WHITE',
  TwoOrMoreRaces = 'TWO_OR_MORE',
  PreferNotToAnswer = 'PREFER_NOT_TO_ANSWER',
}

export enum VeteranStatus {
  ActiveDutyWartimeOrCampaignBadgeVeteran = 'ACTIVE_DUTY_WARTIME_OR_CAMPAIGN_BADGE_VETERAN',
  ArmedForcesServiceMedalVeteran = 'ARMED_FORCES_SERVICE_MEDAL_VETERAN',
  DisabledVeteran = 'DISABLED_VETERAN',
  RecentlySeparatedVeteran = 'RECENTLY_SEPARATED_VETERAN',
  NotAVeteran = 'NOT_A_VETERAN',
  ProtectedVeteran = 'PROTECTED_VETERAN',
  PreferNotToAnswer = 'PREFER_NOT_TO_ANSWER',
}

export enum EmergencyContactRelationship {
  Spouse = 'SPOUSE',
  Parent = 'PARENT',
  Child = 'CHILD',
  Sibling = 'SIBLING',
  Other = 'OTHER',
}

export enum AppRole {
  Admin = 'ADMIN',
  Foreman = 'FOREMAN',
  Worker = 'WORKER',
}
