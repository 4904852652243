import { ComponentProps, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { Dialog, DialogBody, DialogFooter, DialogHeader, DialogSurface } from '@hammr-ui/components/dialog';
import Button from '@/hammr-ui/components/button';

export interface ConfirmDialogProps<T> {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data?: T;
  onConfirm?: (data: T) => void | Promise<void>;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  icon?: ReactNode;
  showConfirmButton?: boolean;
  confirmButton?: ComponentProps<typeof Button>;
  confirmButtonText?: string;
  cancelButtonText?: string;
  children?: ReactNode;
}

export default function ConfirmDialog<T>({
  open,
  setOpen,
  onConfirm,
  data,
  title,
  subtitle,
  icon,
  showConfirmButton = true,
  confirmButton,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  children,
}: ConfirmDialogProps<T>) {
  const [isConfirming, setIsConfirming] = useState(false);

  async function handleConfirm() {
    setIsConfirming(true);

    try {
      await onConfirm(data);
      setOpen(false);
    } finally {
      setIsConfirming(false);
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogSurface>
        <DialogHeader icon={icon} title={title} subtitle={subtitle} showCloseButton className="items-center" noBorder />
        {children && <DialogBody className="border-t border-soft-200">{children}</DialogBody>}
        <DialogFooter>
          <Button type="button" fullWidth variant="outline" color="neutral" size="small" onClick={() => setOpen(false)}>
            {cancelButtonText}
          </Button>
          {showConfirmButton ? (
            <Button
              type="submit"
              fullWidth
              color="primary"
              size="small"
              onClick={() => handleConfirm()}
              loading={isConfirming}
              {...confirmButton}
            >
              {confirmButtonText}
            </Button>
          ) : undefined}
        </DialogFooter>
      </DialogSurface>
    </Dialog>
  );
}
