export const colorList = [
  'rgb(var(--raw-faded-lighter))',
  'rgb(var(--raw-information-lighter))',
  'rgb(var(--raw-warning-lighter))',
  'rgb(var(--raw-error-lighter))',
  'rgb(var(--raw-success-lighter))',
  'rgb(var(--raw-feature-lighter))',
  'rgb(var(--raw-verified-lighter))', //
  'rgb(var(--raw-away-lighter))',
  'rgb(var(--raw-highlighted-lighter))', //
  'rgb(var(--raw-stable-lighter))', //
];
