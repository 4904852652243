import { handleNetworkError, logError, validateNetworkResponse } from './errorHandling';

export const fixPaginationUrl = (paginateUrl: string): string => {
  return `/api/${paginateUrl
    .replace('https://sandbox.checkhq.com/', '')
    .replace('https://api.checkhq.com/', '')
    .replace('http://localhost:8000/', '')}`;
};

export const defaultHammrHeaders = {
  'X-API-KEY': process.env.NEXT_PUBLIC_API_TOKEN,
  platform: 'web',
  'app-version': '1',
};

const DEFAULT_OPTIONS = {
  method: 'GET',
  body: {},
  urlParams: {},
  convertToJson: true,
};

export const apiRequest = async <T = any>(
  path: string,
  options: {
    method?: string;
    body?: any;
    urlParams?: Record<string, any>;
    convertToJson?: boolean;
  } = DEFAULT_OPTIONS
): Promise<T> => {
  const { method = 'GET', body, urlParams } = options;
  let url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/${path}`;

  const searchParams = new URLSearchParams();
  if (urlParams) {
    Object.entries(urlParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => searchParams.append(key, item.toString()));
      } else if (value !== undefined && value !== null) {
        searchParams.append(key, value.toString());
      }
    });
  }
  const transformedUrlParams = searchParams.toString();

  let fetchOptions: any = {
    method: method,
    credentials: 'include',
    cache: 'no-cache',
    headers: {
      ...defaultHammrHeaders,
      'Content-Type': 'application/json',
    },
  };

  if (method === 'POST' || method === 'PATCH') {
    fetchOptions = { ...fetchOptions, body: JSON.stringify(body) };
  }

  if (method === 'GET' && transformedUrlParams) {
    url = `${url}?${transformedUrlParams}`;
  }

  try {
    const res = await fetch(url, fetchOptions);

    await validateNetworkResponse(res);

    if (options.convertToJson !== false) {
      const json = await res.json();
      return json.data;
    } else {
      return res as T;
    }
  } catch (err) {
    console.log(`Error fetching request ${method}-${path}: ${err}`);
    logError(err);
    await handleNetworkError(err);
    throw err;
  }
};

export const checkRequestPaginated = async (response, depth = 1) => {
  let allResults = response.results;
  let nextUrl = response.next;

  for (let i = 1; i < depth; i++) {
    if (nextUrl) {
      const data = await apiRequestCheck(
        nextUrl.replace('https://sandbox.checkhq.com/', '').replace('https://api.checkhq.com/', '')
      );
      allResults = allResults.concat(data.results);
      nextUrl = data.next;
    }
  }

  return allResults;
};

export const apiRequestCheck = async (
  path: string,
  options: {
    method?: string;
    body?: any;
    urlParams?: Record<string, any>;
    convertToJson?: boolean;
    validateResponse?: boolean;
  } = DEFAULT_OPTIONS
) => {
  const { method = 'GET', body, urlParams, validateResponse = true } = options;
  let url = `/api/${path}`;
  const transformedUrlParams = new URLSearchParams(urlParams).toString();

  let fetchOptions: any = {
    method: method,
    credentials: 'include',
    headers: {
      ...defaultHammrHeaders,
      'Content-Type': 'application/json',
    },
  };

  if (method === 'POST' || method === 'PATCH') {
    fetchOptions = { ...fetchOptions, body: JSON.stringify(body) };
  }

  if (method === 'GET' && Object.keys(urlParams ?? {}).length > 0) {
    url = `${url}?${transformedUrlParams}`;
  }

  try {
    const res = await fetch(url, fetchOptions);

    if (validateResponse) {
      await validateNetworkResponse(res);
    }

    if (options.convertToJson === false) {
      return res;
    } else {
      const json = await res.json();
      return json;
    }
  } catch (err) {
    console.log(`Error fetching request ${method}-${path}: ${err}`);
    logError(err);
    await handleNetworkError(err);
    throw err;
  }
};
